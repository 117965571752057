<template>
  <v-row>
    <!-- Map View -->
    <v-col cols="12" md="12" sm="12">
      <v-card class="mapLocal">
        <!-- Map -->
        <gmap-map
          ref="mapRef"
          key="mapKey"
          id="map"
          :center="center"
          :zoom="zoom"
          class="gMap"
          :map-type-id="mapType"
          style="width: 100%; height: 100%; z-index: 1"
          @click="addMarker"
          @zoom_changed="getZoom"
          @bounds_changed="getBounds"
          :options="{
              scrollwheel: true,
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: true,
              streetViewControl: false,
              rotateControl: true,
              fullscreenControl: false,
              styles: mapConfig,
          }"
        >
          <!-- Markers -->
          <gmap-marker
            v-if="place && place.geometry"
            :options="{
              draggable: true,
            }"
            :position="{
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            }"
            @dragend="updateCoordinates"
          />
          <gmap-marker
            :options="{ draggable: true }"
            title="New Location"
            v-if="!place"
            :position="this.center"
            @dragend="updateCoordinates"
            icon="https://img.icons8.com/metro/40/25801f/marker.png"
          />

          <gmap-marker
            :clickable="true"
            @rightclick="toggleInfoWindow(m)"
            @click="viewMarker(m)"
            :position="{
              lat: m.lat,
              lng: m.lng,
            }"
            :key="m.name"
            v-for="m in displayMarkers"
            :title="m.name"
          />

          <gmap-marker
            class="markerWaypoints"
            :clickable="true"
            @rightclick="toggleInfoWindow(m)"
            @click="viewMarker(m)"
            :position="{
              lat: m.lat,
              lng: m.lng,
            }"
            :key="m.name"
            v-for="(m, index) in routeWaypoints"
            :label="index + 1 + '' "
          />


          <!-- Info Window -->
          <gmap-info-window
            :position="infoPosition"
            :opened="infoOpened"
            :options="infoOptions"
            @closeclick="infoOpened = false"
          >
            <div v-html="infoWindow.template"></div>
          </gmap-info-window>

          <!-- Polygons -->
          <gmap-polygon
            v-for="poly in polygonsList"
            :paths="poly.paths"
            v-bind:key="poly.id"
            :options="{
              strokeColor: poly.strokeColor,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: poly.fillColor,
              fillOpacity: 0.35,
            }"
          >
          </gmap-polygon>

          <!-- Circles -->
          <gmap-circle
            :radius="c.radius"
            :center="{
              lat: c.lat,
              lng: c.lng,
            }"
            :fillColor="radiusBar.fillColor"
            :fillOpacity="radiusBar.fillOpacity"
            :strokeColor="radiusBar.strokeColor"
            :strokeOpacity="radiusBar.strokeOpacity"
            :key="c.id"
            v-for="c in pois"
          ></gmap-circle>

          <gmap-circle
            :radius="radius"
            :center="center"
            :fillColor="radiusBar.fillColor"
            :fillOpacity="radiusBar.fillOpacity"
            :strokeColor="radiusBar.strokeColor"
            :strokeOpacity="radiusBar.strokeOpacity"
          ></gmap-circle>
          
          <div v-if="route">
          <gmap-polyline
                v-for="(leg,index) in legs"
                :key="index"
                :path="leg.path"
                :options="{
                    strokeColor: leg.color,
                    strokeOpacity: 0.9,
                    strokeWeight: 5,
                }"
            />
          </div>
          
        </gmap-map>
      </v-card>

      <!-- Country Pop-Out -->
      <transition name="slide-country">
        <v-card class="countrySelectCard" v-if="revealCountryAuto">
          <v-autocomplete
            ref="countrySelect"
            class="countryAuto"
            clearable
            outlined
            dense
            :items="countries"
            item-text="name"
            item-value="id"
            :menu-props="{ closeOnContentClick: true }"
            return-object
            v-model="selectedCountry"
            @change="countryChange"
            @clear="countryClear"
            :loading="loadingCountry"
          >
            <template v-slot:selection="data">
              <v-avatar size="32" v-if="data.item">
                <v-img
                  v-if="data.item.iso2"
                  contain
                  :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                ></v-img>
              </v-avatar>
              <span v-if="data.item" class="ml-2">
                {{ data.item.name }}
              </span>
            </template>
            <template v-slot:item="data">
              <v-list-item-avatar>
                <v-img
                  contain
                  v-if="data.item.iso2"
                  :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-card>
      </transition>

      <!-- Location Search -->
      <v-card class="toolbar" flat >
        <v-row>
          <v-btn id="country-filter" icon class="countryAvatar" @click="revealCountry">
            <v-avatar size="38">
              <v-img
                v-if="selectedCountry.iso2"
                :src="`https://cdn.loglive.io/flags/4x3/${selectedCountry.iso2.toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
          </v-btn>
          <gmap-autocomplete

            key="autocompleteKey"
            class="form-control"
            @place_changed="setPlace"
            :componentRestrictions="countrySelected"
          >
            <template v-slot:default="slotProps" >
              <v-text-field
                id="country-search"
                outlined
                dense
                clearable
                label="Location"
                class="locationInput"
                prepend-inner-icon="place"
                :append-outer-icon="appendIcon"
                @click:append-outer="expandedInfo()"
                @click:clear="clearPlace"
                v-model="placeName"
                placeholder="Search for a location"
                persistent-placeholder
                ref="input"
                v-on:listeners="slotProps.listeners"
                v-on:attrs="slotProps.attrs"
                @change="viewInfo = true"
                color="primary"
              />
            </template>
          </gmap-autocomplete>
          <v-divider vertical class="ml-3 mt-4 mb-5"></v-divider>
          <span class="mt-5 ml-2">
            <v-btn icon disabled>
              <v-icon>mode</v-icon>
            </v-btn>
            <v-btn icon disabled>
              <v-icon>polyline</v-icon>
            </v-btn>
            <v-tooltip bottom open-delay="200">
              <template v-slot:activator="{ on }">
                <v-btn style="overflow: visible; z-index: 2000 !important" icon @click="radiusCard" v-on="on" id="radius-tool">
                  <v-icon>adjust</v-icon>
                </v-btn>
              </template>
              <span>Radius</span>
            </v-tooltip>
          </span>
        </v-row>
      </v-card>

      <!-- Radius Pop-out -->
      <transition name="slide-top">
        <v-card class="radiusTool" v-if="radiusTool">
          <span
            class="d-flex justify-center"
            style="opacity: 70%; margin-top: 0.4vh"
          >
            Radius Tool</span
          >
          <v-slider
            max="5000"
            v-model="radius"
            thumb-label
            thumb-size="28"
            style="margin-top: -0.8vh"
          ></v-slider>
        </v-card>
      </transition>

      <!-- Google Search Expanded Information Card -->
      <v-expand-transition>
        <v-card v-if="expand" class="expandedInfo">
          <v-row>
            <v-col cols="12" md="12" sm="12" class="mb-0 pb-0">
              <v-text-field
                outlined
                dense
                label="Address"
                class="locationAddress"
                v-model="formattedAddress"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" class="my-0 py-0">
              <v-text-field
                outlined
                dense
                label="Postal Code"
                class="locationData"
                v-model="postalCode"
              />

              <v-text-field 18.8379889
                outlined
                dense
                label="Latitude"
                class="locationData"
                v-model="chosenLatitude"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" class="my-0 py-0">
              <v-text-field
                outlined
                dense
                label="Country"
                class="locationData"
                v-model="selectedCountry.name"
              />

              <v-text-field
                outlined
                dense
                label="Longitude"
                class="locationData"
                v-model="chosenLongitude"
                
              />
            </v-col>
          </v-row>
        </v-card>
      </v-expand-transition>

      <!-- ADD POI Information Card -->
      <transition name="slide-info">
        <v-card key="poiKey" v-if="viewInfo" class="informationCard" id="point-of-interest">
          <v-tooltip top open-delay="200">
            <template v-slot:activator="{ on }">
              <v-btn @click="viewInfo = false, placeholderKey++" icon class="infoHide" v-on="on"
                ><v-icon>chevron_right</v-icon></v-btn
              >
            </template>
            <span> Hide Info</span>
          </v-tooltip>
          <v-card-subtitle class="d-flex justify-center">
            Add Point of Interest
            <v-btn icon
            v-if="editingPoi"
            style="position: absolute; right: 0.6vw; top: 1vh;"
            color="primary"
            @click="clearPoi"
            ><v-icon size="30">close</v-icon></v-btn>
          </v-card-subtitle>
          <v-card-text>
            <v-row id="locationName">
            <v-text-field
              placeholder="Enter Location Name"
              hint="Autocompleted by Google Search"
              outlined
              dense
              style="margin: 1.5vh 0.5vw 0.2vh 0.5vw"
              @change="checkRequired"
              label="Location Name*"
              persistent-placeholder
              v-model="placeName"
              hide-details
              prepend-inner-icon="edit_note"
            /></v-row>
            <v-row id="functionality">
            <v-text-field
                outlined
                dense
                style="margin: 1.5vh 0.5vw 0.2vh 0.5vw"
                label="Friendly Name"
                persistent-placeholder
                v-model="friendlyName"
                prepend-inner-icon="edit_note"
              />
            </v-row>
            <v-row id="functionality">
            <v-select
              outlined
              dense
              hide-selected
              multiple=""
              small-chips
              deletable-chips
              counter="5"
              @change="checkRequired('function')"
              placeholder="Select Function"
              persistent-placeholder
              label="Function*"
              prepend-inner-icon="warehouse"
              style="margin: -1vh 0.5vw 0.2vh 0.5vw;  "
              v-model="selectedFunction"
              :items="locationTypes"
              :menu-props="{ bottom: true, offsetY: true }"
            ></v-select> </v-row>
            <v-row id="organisationName">
            <v-autocomplete
              style="margin: -1vh 0.5vw 0.2vh 0.5vw"
              persistent-placeholder
              placeholder="Search for an organisation"
              outlined
              dense
              hide-no-data
              clearable
              return-object
              @change="checkRequired"
              label="Organisation*"
              v-model="selectedOrganisation"
              prepend-inner-icon="business"
              :loading="searchingOrganisations"
              :search-input.sync="searchOrganisations"
              :menu-props="{ closeOnContentClick: true }"
              :items="organisations.data"
              @input="getFboCodes"
              item-text="name"
              item-value="id"
            > 
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            </v-row>
            <v-row id="locationCode">
            <v-text-field  style="margin: -1vh 0.1vw 0.8vh 0.5vw" label="Location Code" v-model="locationCode" outlined dense clearable></v-text-field>
            </v-row>
            <v-row id="alias">
            <v-text-field
              outlined
              :key="aliasKey"
              style="margin: -1vh 0.1vw 0.8vh 0.5vw"
              dense
              persistent-hint
              readonly
              clearable
              persistent-placeholder
              v-model="aliases[0].alias"
              placeholder="Add an alternative name"
              label="Alias"
              prepend-inner-icon="new_label"
              class="activityPoint"
              :loading="loadingAliases"
            >
              <template v-slot:append-outer>
                <v-tooltip top open-delay="250">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      @click="openAlias"
                      v-on="on"
                      style="bottom: 0.7vh"
                    >
                      <v-icon color="edit" size="30" style="margin-right: 0.1vw"
                        >add_circle_outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Add an Alternative Name</span>
                </v-tooltip>
              </template>
            </v-text-field> </v-row>
            <v-row id="activity-point">
            <v-text-field
              outlined
              style="margin: -1vh 0.1vw 0.8vh 0.5vw"
              dense
              persistent-hint
              readonly
              clearable
              @change="checkRequired"
              v-model="selectedAP.code"
              :hint="selectedAP.name"
              label="Activity-Point"
              prepend-inner-icon="domain_add"
              class="activityPoint"
              :loading="loadingAps"
              :disabled="!selectedOrganisation"
            >
              <template v-slot:append-outer>
                <v-tooltip top open-delay="250">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      @click="addActivityPoint"
                      v-on="on"
                      style="bottom: 0.7vh"
                    >
                      <v-icon color="edit" size="30" style="margin-right: 0.1vw"
                        >add_circle_outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Add Activity Point</span>
                </v-tooltip>
              </template>
            </v-text-field> </v-row>
            <v-row id="fbo-code">
            <v-text-field
              outlined
              dense
              persistent-hint
              readonly
              clearable
              @change="checkRequired"
              @click:clear="clearFboSelected"
              v-model="selectedFbo.fboCode"
              :hint="selectedFbo.fboName"
              label="FBO-Code"
              prepend-inner-icon="tag"
              style="margin: -1vh 0.1vw 0.8vh 0.5vw"
              :loading="loadingFboCodes"
              :disabled="!selectedOrganisation"
              :error="fboCodeUsed"
              :error-messages="fboCodeUsed ? 'FBO-Code already in use' : ''"
            >
              <template v-slot:append-outer>
                <v-tooltip top open-delay="250">
                  <template v-slot:activator="{ on }">
                    <v-btn  icon @click="addFbo" v-on="on" style="bottom: 0.7vh">
                      <v-icon color="edit" size="30" style="margin-right: 0.1vw"
                        >add_circle_outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Add FBO-Code</span>
                </v-tooltip>
              </template>
            </v-text-field> </v-row>
            
            <v-divider style="margin: 0 0 1.5vh 0"></v-divider>
            <v-row id="locationGeoInfo">
              <v-col cols="12" md="12" sm="12" >
                <v-autocomplete
                  persistent-placeholder
                  placeholder="Search for the nearest Town/City"
                  outlined
                  dense
                  hide-no-data
                  clearable
                  return-object
                  label="Town/City*"
                  v-model="selectedLocode"
                  :loading="searchingLocodes"
                  :search-input.sync="searchLocodes"
                  :menu-props="{ closeOnContentClick: true }"
                  :items="locodes.data"
                  item-text="name"
                  item-value="id"
                >     
                <template v-slot:selection="data">
                     <span> {{ data.item.name }} - <b>{{ data.item.locode }}</b></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }} - <b>{{ item.locode }}</b></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="6" style="margin: -3.5vh 0 0 0">
                <v-text-field
                  persistent-placeholder
                  outlined
                  readonly
                  dense
                  @change="checkRequired"
                  label="Country"
                  v-model="selectedCountry.name"
                />
                </v-col>
                <v-col cols="12" md="6" sm="6" style="margin: -3.5vh 0 0 0">
                <v-text-field
                  persistent-placeholder
                  outlined
                  @change="checkRequired"
                  dense
                  label="Province"
                  v-model="province"
                />
                </v-col>                                                  
                <v-col cols="12" md="6" sm="6" style="margin: -3.5vh 0 0 0" >
                <v-text-field
                  persistent-placeholder
                  readonly
                  outlined
                  hint="Autocompleted by Google Search"
                  dense
                  @change="checkRequired"
                  label="Latitude*"
                  v-model="chosenLatitude"
                />
              </v-col>
                <v-col cols="12" md="6" sm="6" style="margin: -3.5vh 0 0 0">
                <v-text-field
                  persistent-placeholder
                  outlined
                  readonly
                  @change="checkRequired"
                  dense
                  label="Longitude*"
                  v-model="chosenLongitude"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-row>
            <v-col cols="12" sm="6" class="d-flex align-center pl-8 pt-0">
              <span>* required fields</span>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex justify-end align-center pt-0 pr-8">
              <!-- Save Button -->
              <v-btn
                v-if="!editingPoi"
                :disabled="saveDisabled"
                :loading="saveLoading"
                color="edit"
                @click="createPoi"
                style="width: 5vw"
              >
                <v-icon size="25">save</v-icon>
                <span style="margin: 0.1vh 0 0 0.3vw">Save</span>
              </v-btn>
            <!-- Editing Button -->
              <v-btn icon
                v-if="editingPoi"
                style="position: absolute; right: 6.2vw; bottom: 1.5vh;"
                color="primary"
                @click="deleteCard = true"
              >
                <v-icon size="30">delete</v-icon>
              </v-btn>
              <v-btn
                v-if="editingPoi"
                :disabled="updateDisabled"
                :loading="updateLoading"
                color="edit"
                @click="updatePoi"
                style="width: 5vw"
              >
                <v-icon size="25">save</v-icon>
                <span style="margin: 0.1vh 0 0 0.3vw">Update</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </transition>

      <!-- Routes Card-->
      <transition name="slide-info">
        <v-card v-if="viewRoutes" class="informationCard">
          <v-toolbar dense flat>
            <span class="infoTitleText">Routes</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="viewRoutes = false, placeholderKey++">
              <v-icon color="primary">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="d-flex justify-center">
            <v-col cols="12" sm="4" md="4" lg="4" class="d-flex justify-center pt-2 pb-1 px-0" style="flex-direction:column; text-align: center;" >
              <v-icon size="2.5vh">local_shipping</v-icon>
              <span>{{ drivingInfo.truckTimeFormatted }}</span>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4" class="d-flex justify-center pt-2 pb-1 px-0" style="flex-direction:column; text-align: center" >
              <v-icon size="2.5vh">directions_car</v-icon> <br>
              <span>{{ drivingInfo.drivingTimeFormatted }}</span>
            </v-col>
          </v-row>
          <v-row style="padding: 0 0.5vw 0 0.5vw">
            <v-col cols="12" sm="12" md="12" lg="12" style="padding: 0 0vw 1.6vh 0vw">
              <v-list nav dense class="pa-0">
                <draggable
                  class="locations"
                  :list="routeWaypoints"
                  :animation="200"
                  group="transUnit"
                  @start="drag = true"
                  @end="drag = false"
                >
                  <v-list-item
                    v-for="(waypoint, index) in routeWaypoints"
                    :key="waypoint.index"
                    color="green"
                  >
                  <v-card class="routeWaypoint" flat>
                    <v-row style="height:inherit; width: inherit; margin: auto;">
                      <v-col cols="12" sm="1" md="1" lg="1" class="d-flex align-start justify-center pa-0 " style="flex-direction: column; height: inherit">
                        <v-icon size="2vh">drag_indicator</v-icon>
                        <span style="display: none">{{ index + 1 }}</span>
                      </v-col>
                      <v-col cols="12" sm="1" md="1" lg="1" class="d-flex justify-center align-center px-0 py-0" style="flex-direction: column">
                        <v-icon size="1.5vh"
                        v-if="routeWaypoints.indexOf(waypoint) != routeWaypoints.length - 1"
                        >radio_button_unchecked</v-icon>
                        <v-icon size="1.8vh" color="primary"
                        v-if="routeWaypoints.indexOf(waypoint) == routeWaypoints.length - 1"
                        >location_on</v-icon>
                        <v-icon size="4vh" style="margin:-0.15vh 0 -4.3vh 0; z-index: 3"
                          v-if="routeWaypoints.indexOf(waypoint) != routeWaypoints.length - 1"
                        >more_vert</v-icon>
                      </v-col>
                      <v-col cols="12" sm="9" md="9" lg="9" class="d-flex justify-center pa-0" style="height: inherit">
                        <v-autocomplete
                          :key="routesLocationKey"
                          v-model="routeWaypoints[index]"
                          hide-details 
                          persistent-placeholder
                          clearable
                          placeholder="Search for a POI or click on the map... "
                          return-object
                          @change="updateWaypoint(waypoint, $event, index)"
                          outlined 
                          dense 
                          :items="poiList"
                          :menu-props="{ closeOnContentClick: true }"
                          :item-text="item => item.name"
                          style="display: flex; justify-content: center; align-items: center;"
                          >
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="1" md="1" lg="1" class="d-flex align-center justify-center pa-0 " style="flex-direction: column; height: inherit">
                        <v-btn icon plain v-if="routeWaypoints.length > 2"><v-icon color="primary" small @click="removeWaypoint(waypoint)">close</v-icon></v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                  </v-list-item>
                </draggable>
              </v-list>
            </v-col>
          </v-row>
          <v-row v-if="routeWaypoints.length < 10">
            <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center pa-0">
              <v-btn plain rounded @click="addWaypoint"> <v-icon>add_circle_outline</v-icon> Add Destination</v-btn>
            </v-col>
          </v-row>
          <v-card flat style="width: 100%; height: 14vh; position: absolute; bottom: 0;  padding: 0.5vh 0.5vw 0.5vh 0.5vw">
            <v-row no-gutters style="height: 14vh">
              <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-start" style="height:2vh">
                <v-icon class="pr-2">moving</v-icon>
                {{ drivingInfo.drivingDistanceFormatted }}
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-start pb-2">
                <v-text-field
                  v-model="routeName"
                  label="Route Name"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center">
                <v-btn color="edit" ><v-icon>save</v-icon>Save Route</v-btn>
              </v-col>
            </v-row>          
          </v-card>
        </v-card>
      </transition>

      <!-- Areas Card -->

      <!-- Settings Card -->
      <transition name="slide-info">
        <v-card v-if="viewSettings" class="informationCard">
          <v-toolbar dense flat>
            <span class="infoTitleText">Settings</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="viewSettings = false, placeholderKey++">
              <v-icon color="primary">close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-row class="px-3">
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card class="infoCardTiles" height="35vh">
              <v-card-subtitle class="pt-2">Map Styles</v-card-subtitle>
              <v-row class="px-2 ">
                <v-col cols="12" sm="6" md="6" lg="6" class=" py-0 pr-2">
                  <v-btn height="100%" width="100%" :depressed="mapDarkMode ? true : false" :color="mapDarkMode ?  'transparent' : 'greyBase'" @click="mapDarkMode = false , mapKey++" class="py-2">
                  <v-avatar class="mr-2 ">
                    <v-img src="../../../public/img/icons/mapLightMode.png"></v-img>
                  </v-avatar>
                  <span>Light</span>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" class="py-0 pl-2">
                  <v-btn height="100%" width="100%" :depressed="mapDarkMode ? false : true" :color="mapDarkMode ?  'greyBase' : 'transparent'"  @click="mapDarkMode = true , mapKey++" class="py-2">
                  <v-avatar class="mr-2">
                    <v-img src="../../../public/img/icons/mapDarkMode.png"></v-img>
                  </v-avatar>
                  <span>Dark</span>
                </v-btn>
                </v-col>
              </v-row>
              <v-divider class="mt-8 mx-2" ></v-divider>
              <v-row no-gutters class="px-2">
                <v-col cols="12" sm="6" md="6" lg="6" >
                  <v-card-subtitle class="pt-2 pl-2">Map Types</v-card-subtitle>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" class="pt-0 pb-2">
                      <v-btn height="100%" width="100%" small class="py-1 mapTypeBtn"
                        @click="mapType = 'hybrid', mapKey++" 
                        :color="mapType == 'hybrid' ? 'greyBase' : 'transparent'" 
                        :depressed="mapType == 'hybrid' ? false : true"
                        >
                        <v-avatar size="40" class="mr-2">
                          <v-img src="../../../public/img/icons/mapSatellite.png"></v-img>
                        </v-avatar>
                        <span>Satellite</span>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" class="pt-0 pb-2 ">
                      <v-btn height="100%" width="100%" small class="py-1 mapTypeBtn"
                        @click="mapType = 'terrain', mapKey++"  
                        :color="mapType == 'terrain' ? 'greyBase' : 'transparent'" 
                        :depressed="mapType == 'terrain' ? false : true"
                        >
                        <v-avatar class="mr-2" size="40">
                          <v-img src="../../../public/img/icons/mapTerrain.png"></v-img>
                        </v-avatar>
                        <span>Terrain</span>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" class="pt-0">
                      <v-btn height="100%" width="100%" small class="py-1 mapTypeBtn"
                        @click="mapType = 'roadmap', mapKey++" 
                        :color="mapType == 'roadmap' ? 'greyBase' : 'transparent'" 
                        :depressed="mapType == 'roadmap' ? false : true"
                        >
                        <v-avatar class="mr-2" size="40">
                          <v-img src="../../../public/img/icons/mapRoadmap.png"></v-img>
                        </v-avatar>
                        <span>Roadmap</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" >
                  <v-card-subtitle class="pt-2 pl-2">Map Options</v-card-subtitle>
                    <v-checkbox v-model="mapLabels" :input-value="mapLabels" label="Labels"></v-checkbox>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12"></v-col>
          <v-col cols="12" sm="12" md="12" lg="12"></v-col>
        </v-row>
        </v-card>
      </transition>


      <!-- Navigation Card -->
      <transition name="slide-nav">
        <v-card id="navigation-card" v-if="viewMarkers" class="navCard">
          <v-btn class="closeNav" @click="viewMarkers = false, placeholderKey++" icon>
            <v-icon color="primary">close</v-icon>
          </v-btn>
          <v-card-subtitle class="d-flex justify-center">
            Navigation
          </v-card-subtitle>
          <v-card-text>
            <v-text-field
              outlined
              autofocus
              dense
              clearable
              placeholder="Search for a location"
              append-icon="search"
              style="width: 15.5vw; margin: -0.5vh 0 -1.5vh 0"
              :loading="searchingPois"
              v-model="params.search"
            >

              <template v-slot:append-outer>
                <v-tooltip top open-delay="250">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      id="poiFilter"
                      :disabled="countryCollision"
                      icon
                      @click="filterPois"
                      v-on="on"
                      style="bottom: 0.7vh"
                    >
                      <v-icon>filter_alt</v-icon>
                    </v-btn>
                  </template>
                  <span>Filter POI's</span>
                </v-tooltip>
              </template>
            </v-text-field>
            <span style="margin: 0 0 0 0">
                  <b>Points of Interest</b></span
                >
            <v-card style="height:58vh; width: 16vw; contain: content;" elevation="0" >
              <v-card tile outlined elevation="0">
              <v-toolbar class="listToolbar">
                <span style="margin: 0 0 3vh -0.1vw; opacity: 90%"> Limit:</span>
                <v-select
                  v-model="params.limit"
                  solo flat
                  :items="limitOptions"
                  dense
                  @change="getMarkers()"
                  style="width: 1.5vw; margin: 0 0 0.6vh 0.5vw ;"
                ></v-select>
                <v-spacer></v-spacer>
                <span style="margin: 0 0.5vw 3vh; opacity: 90%">Select All</span>
                <v-checkbox
                  v-model="selectAll"
                  dense
                  style="margin: 0 -0.4vw 1.5vh 0"
                ></v-checkbox>
              </v-toolbar>
            </v-card>
              <v-card tile outlined style="overflow-y: auto; overflow-x: hidden; height: 54vh; contain: content" elevation="0">
              <v-list style=" width: auto;">
                <v-list-item-group>
                  <v-progress-circular
                    v-if="loadingPoiList"
                    class="loadingPoi"
                    indeterminate
                    color="white"
                    size="40"
                  ></v-progress-circular>
                  <v-list-item
                    id="poiFull"
                    @click="viewMarker(poi)"
                    dense
                    two-line
                    v-for="poi in poiList"
                    :key="poi.id"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ poi.name }}</v-list-item-title>
                      <v-list-item-subtitle>
                       <span v-if="poi.poiFunctions.length > 0"> {{poi.poiFunctions[0].functionality}}</span>
                       <span v-if="poi.poiFunctions.length > 1">, {{poi.poiFunctions[1].functionality}}</span>
                       <span v-if="poi.poiFunctions.length > 2">, {{poi.poiFunctions[2].functionality}} </span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        id="poi"
                        :value="poi.id"
                        v-model="selectedMarkers"
                        color="primary"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
            </v-card>
          </v-card-text>
        </v-card>
      </transition>

      <!-- Saved Routes -->
      <transition name="slide-nav">
        <v-card id="allRoutes" v-if="viewSavedRoutes" class="navCard">
          <v-toolbar dense flat>
            <span class="infoTitleText">All Routes</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="viewSavedRoutes = false, placeholderKey++">
              <v-icon color="primary">close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </transition>

      <!-- POI Filter -->
      <transition name="filter">
        <v-card class="filterPoi" v-if="filterPoiCard">
          <v-card-subtitle class="d-flex justify-center">
            Filter POI's
          </v-card-subtitle>
          <v-card-text >
            <v-select
              outlined
              dense
              clearable
              :items="locationTypes"
              v-model="params.filter.functionality"
              label="Function"
              :loading="functionLoading"
            />
            <v-select
              outlined
              dense
              clearable
              :items="pois"
              v-model="params.filter.subdivision"
              item-text="subdivision"
              item-value="subdivision"
              label="Province"
              :loading="subdivisionLoading"
            />
            <v-btn
              class="filterOff"
              :disabled="!filtersActive"
              icon
              @click="clearFilters"
            >
              <v-icon>filter_alt_off</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </transition>

      <!-- Hidden Info Placeholder -->
      <transition name="slide-btn">
        <v-hover :key="placeholderKey"  open-delay="400" v-slot="{hover}">
        <v-card  class="firstTab floatingTabsRight" :class="{'on-hover-right' : hover}" @click="viewInfo = true, getMarkers()" v-if="!viewInfo && !viewInfo && !viewSettings && !viewAreas">
          <v-btn icon plain @click="viewInfo = true"
            ><v-icon x-large>add_business</v-icon></v-btn
          >
          <span style="font-size: large; position:absolute; right: 0.5vw">Add POI</span>
        </v-card>
      </v-hover>
      </transition>

      <!-- Hidden Routes Placeholder -->
      <transition name="slide-btn">
        <v-hover :key="placeholderKey"  open-delay="200" v-slot="{hover}">
        <v-card class="secondTab floatingTabsRight" @click="viewRoutes = true, viewSavedRoutes = true" v-if="!viewRoutes && !viewInfo && !viewSettings && !viewAreas" :class="{'on-hover-right' : hover}">
          <v-btn icon plain @click="viewRoutes = true"
            ><v-icon style="transform: scaleX(-1)" x-large>mode_of_travel</v-icon></v-btn
          >
          <span style="font-size: large; position:absolute; right: 0.5vw">Add Routes</span>
        </v-card>
      </v-hover>
      </transition>

      <!-- Hidden Area Placeholder -->
      <transition name="slide-btn">
        <v-hover :key="placeholderKey"  open-delay="200" v-slot="{hover}">
        <v-card class="thirdTab floatingTabsRight" @click="viewAreas = true" :class="{'on-hover-right' : hover}"
                v-if="!viewRoutes && !viewInfo && !viewSettings && !viewAreas" >
          <v-btn icon plain @click="viewAreas = true"
            ><v-icon x-large>architecture</v-icon></v-btn
          >
          <span style="font-size: large; position:absolute; right: 0.5vw">Add Areas</span>
        </v-card>
      </v-hover>
      </transition>

      <!-- Hidden Settings Placeholder -->
      <transition name="slide-btn">
        <v-hover :key="placeholderKey" open-delay="200" v-slot="{hover}">
        <v-card class="fourthTab floatingTabsRight" @click="viewSettings = true" :class="{'on-hover-right' : hover}"
                v-if="!viewRoutes && !viewInfo && !viewSettings && !viewAreas" >
          <v-btn icon plain @click="viewSettings = true"
            ><v-icon x-large>settings</v-icon></v-btn
          >
          <span style="font-size: large; position:absolute; right: 0.5vw">Settings</span>
        </v-card>
      </v-hover>
      </transition>

      <!-- View Navigation Button -->
      <transition name="slide-marker">
        <v-hover :key="placeholderKey" open-delay="200" v-slot="{hover}">
        <v-card class="firstTab floatingTabsLeft" v-if="!viewMarkers" @click="viewMarkers = true" id="navigation-btn" :class="{'on-hover-left' : hover}">
          <span style="font-size: large; position:absolute; left: 0.5vw">Navigation</span>
          <v-btn icon plain @click="viewMarkers = true">
            <v-icon x-large>explore</v-icon>
          </v-btn>
        </v-card>
      </v-hover>
      </transition>

      <!-- View All Routes -->
      <transition name="slide-marker">
        <v-hover :key="placeholderKey" open-delay="200" v-slot="{hover}">
        <v-card class="secondTab floatingTabsLeft" v-if="!viewSavedRoutes" @click="viewSavedRoutes = true" id="allRoutes-btn" :class="{'on-hover-left' : hover}">
          <span style="font-size: large; position:absolute; left: 0.5vw">All Routes</span>
          <v-btn icon plain @click="viewSavedRoutes = true">
            <v-icon x-large>route</v-icon>
          </v-btn>
        </v-card>
      </v-hover>
      </transition>

      <!-- FBO Dialog Card -->
      <v-dialog v-model="fboCard" max-width="650">
        <v-card height="425" style="overflow-y: hidden">
          <v-toolbar dark
            >Get FBO-Code
            <v-spacer></v-spacer>
            <v-btn icon @click="addFbo"
              ><v-icon large color="primary">close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text>
            <v-row class="d-flex justify-center">
              <v-col cols="12" md="6" sm="6">
                <v-text-field
                  outlined
                  dense
                  v-model="fboSearchAll"
                  :loading="searchingFbos"
                  clearable
                  class="dataSearch"
                  append-icon="search"
                  placeholder="Search FBO-Code or Organisation"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-card style="overflow-y: auto; overflow-x: hidden">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-list height="300">
                    <v-list-item
                      :loading="searchingFbos"
                      three-line
                      outlined
                      dense
                      v-for="item in moreFboCodes.data"
                      :key="item.id"
                      @click="selectFbo(item)"
                    >
                      <v-list-item-avatar>
                        <v-icon left>business</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span>{{ item.fboCode }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.fboName }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ item.fboType }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Activity Point Dialog -->
      <v-dialog v-model="activityPointCard" max-width="650">
        <v-card height="425" style="overflow-y: hidden">
          <v-toolbar dark
            >Get Activity-Point
            <v-spacer></v-spacer>
            <v-btn icon @click="addActivityPoint"
              ><v-icon large color="primary">close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text>
            <v-row class="d-flex justify-center">
              <v-col cols="12" md="6" sm="6">
                <v-text-field
                  outlined
                  dense
                  v-model="acitvityPointSearch"
                  :loading="searchingActivityPoints"
                  clearable
                  class="dataSearch"
                  append-icon="search"
                  placeholder="Search Activity Point or Office"
                  
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-card style="overflow-y: auto; overflow-x: hidden">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-list height="300">
                    <v-list-item
                      :loading="searchingActivityPoints"
                      two-line
                      outlined
                      dense
                      v-for="item in moreActivities.data"
                      :key="item.id"
                      @click="selectActivityPoint(item)"
                    >
                      <v-list-item-avatar>
                        <v-icon left>business</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span>{{ item.name }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.code }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Alias Card -->
      <v-dialog v-model="aliasCard" max-width="650">
        <v-card height="550" style="overflow-y: hidden">
          <v-toolbar style="height: 10vh" dark>
            <span style="margin: 0 0 -4vh 0; font-size: larger"><p> Add an Alias <br /></p>
             <span style="font-size: small; font-style:italic; opacity: 0.6; line-height: 0.2 "><p>This is because there are multiple names for the same places.<br/></p>
               <p>ex. Cape Town Container Terminal is also known as the Ben Schoeman Container Terminal</p></span></span>
            <v-spacer></v-spacer>
            <v-btn icon @click="openAlias"
              ><v-icon large color="primary">close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text>
            <v-row class="d-flex justify-center">
              <v-col cols="12" md="12" sm="12" style="display: grid; justify-items: center" >
                <v-text-field
                  outlined
                  dense
                  label="Alias"
                  persistent-placeholder
                  v-model="aliasToAdd.alias"
                  clearable
                  class="aliasAddBar"
                  placeholder="Add an Alternative Name for this POI"
                  @keyup.enter="addAlias"
                >
                </v-text-field>
                <v-autocomplete
                  style="width: 18vw; margin: -1vh 0 -1.5vh 0;"
                  persistent-placeholder
                  placeholder="What organisation uses this Alias?"
                  outlined
                  dense
                  hide-no-data
                  clearable
                  return-object
                  label="Associated Organisation"
                  v-model="aliasToAdd.aliasOrganisation"
                  prepend-inner-icon="business"
                  :loading="searchingAliasOrganisations"
                  :search-input.sync="searchAliasOrganisations"
                  :menu-props="{ closeOnContentClick: true }"
                  :items="aliasOrganisations.data"
                  item-text="name"
                  item-value="id"
                > 
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-btn @click="addAlias" :disabled="aliasDisabled" color="success">
                  Accept
                </v-btn>
              </v-col>
            </v-row>
            <v-card flat style="overflow-y: auto; overflow-x: hidden">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-list height="300" v-if="aliases[0].alias != null">
                    <v-list-item
                      two-line
                      outlined
                      dense
                      v-for="item in aliases"
                      :key="item.alias"
                    >
                      <v-list-item-avatar>
                        <v-icon left>new_label</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span>{{ item.alias }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.aliasType }}
                        </v-list-item-subtitle>

                      </v-list-item-content>
                      <v-list-item-action>
                          <v-btn icon @click="removeAlias(item)">
                            <v-icon color="error">delete</v-icon>
                          </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteCard" max-width="30vw">
        <v-card style="height: 15vh; width: 30vw;">
          <v-card-title class="d-flex justify-center"> Are you sure you want to delete this Point of Interest?</v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="primary" @click="deletePoi">Yes</v-btn>
            <v-btn color="primary" @click="deleteCard = false">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


    </v-col>
  </v-row>
</template>

<script>
import { getGoogleMapsAPI } from "gmap-vue";


import draggable from "vuedraggable";
import { nextTick } from 'vue';

export default {
  components: {
    draggable,
  },
  data: () => ({

    countries: [],
    places: [],
    fboCodes: [],
    aliases: [
      {
        alias: null,
        aliasOrganisation: null
      }
    ],
    // ROUTES
    route: null,
    routeName: null,
    legs: [],
    fullRoute: [],
    routeWaypoints: [
      {
        lat: null,
        lng: null,
        name:null,
      },
      {
        lat: null,
        lng: null,
        name:null,
      },
    ],
    colors: [
      "#ff6666",      
      "#00aaff",
      "#0becb8",
      "#9068af",
      "#006bc3",
      "#f37aaf",
      "#f9f871",
      "#ffb96f",
      "#006d66",
      "#00b673"
    ],
    // 

    moreFboCodes: [],
    moreActivities: [],
    selectedOrganisation: null,
    selectedLocode: null,
    locationCode: null,
    polygons: [],
    polygonsList: [],
    morePois: [],
    pois: [],
    poiList: [],
    selectedMarkers: [],
    selectedFbo: {},
    selectedAP: {},
    chosenPoi:[],
    selectedFunction: null,
    existingAliases: [],
    aliasToAdd: {
      alias: null,
      aliasOrganisation: null,
    },
    deletedAliases: [],
    aliasType: null,
    functionLimit: 5,
    place: null,
    
    zoomLevel: 10,
    radius: 0,
    placeholderKey: 0,
    mapKey: 0,
    routesLocationKey: 0,
    poiKey: 0,
    aliasKey: 0,
    poiLimit: 20,
    fboList: 0,
    actPointList: 0,
    autocompleteKey: 0,
    customName: "",
    address: "",
    formattedAddress: "",
    province: "",
    placeName: null,
    country: "",
    coordinates: "",
    chosenLatitude: null,
    chosenLongitude: null,
    postalCode: "",
    radiusTool: false,
    fboCodeUsed: false,
    drag: false,
    deleteCard: false,
    editingPoi: false,
    saveDisabled: true,
    countryCollision: false,
    filtersActive: false,
    filterPoiCard: false,
    fboSearch: null,
    searchingOrganisations: false,
    searchingAliasOrganisations: false,
    searchingLocodes: false,
    aliasDisabled: true,
    viewMarkers: true,
    functionLoading: false,
    updateLoading: false,
    updateDisabled: false,
    subdivisionLoading: false,
    loadingCountry: false,
    loadingFboCodes: false,
    loadingAliases: false,
    loadingAps: false,
    saveLoading: false,
    loadingPoiList: true,
    searchingFbos: false,
    searchingPois: false,
    searchingActivityPoints: false,
    searchOrganisations: null,
    searchAliasOrganisations: null,
    searchLocodes: null,
    searchPois: null,
    fboSearchAll: null,
    acitvityPointSearch: null,
    functionFilter: null,
    subdivisionFilter: null,
    zoom: 11,
    revealCountryAuto: false,
    fboCard: false,
    aliasCard: false,
    activityPointCard: false,
    organisationTimeout: null,
    aliasOrgTimeout: null,
    locodeTimeout: null,
    poiSearchTimeout: null,
    fboSearchTimeout: null,
    activitySearchTimeout: null,
    viewInfo: false,
    viewRoutes: false,
    viewSavedRoutes: false,
    viewAreas: false,
    viewSettings: false,
    expand: false,
    customMarker: true,
    mapCursor: "pointer",
    appendIcon: "expand_more",
    countrySelected: { country: "za" },
    limitOptions: [10, 20, 50, "All"],
    currentBounds: {
      north: null,
      south: null,
      east: null,
      west: null,
    }, 
    radiusBar: {
      fillColor: "white",
      fillOpacity: 0.5,
      strokeColor: "red",
      strokeOpacity: 0.8,
    },
    center: {
      lat: -33.9321,
      lng: 18.8602,
     
    },
    bounds: {
      north: -17.31542356200097,
      south: -33.48808586,
      east: 43.30981,
      west: 10.62930204,
    },
    organisations: {
      total: 0,
      data: [],
    },
    aliasOrganisations: {
      total: 0,
      data: [],
    },
    locodes: {
      total: 0,
      data: [],
    },
    selectedCountry: {
      continent: "Africa",
      id: 343,
      name: "South Africa",
      iso2: "ZA",
    },
    params: {
      search: "",
      limit: 20,
      filter: {
        functionality: null,
        subdivision: null
      }
    },
    locationTypes: [
      "Farm",
      "Pack-House (On-Farm)",
      "Pack-House (Off-Farm)",
      "Dry Storage",
      "Commercial Cold Store",
      "Warehouse",
      "Distribution Center",
      "Drying Facility",
      "Weigh-Bridge",
      "Toll-Gate",
      "Truck-Depot",
      "Port",
      "Port Terminal",
      "Airport Terminal",
      "Exporter",
      "Head-Office",
      "Regional Office",
      "Container Depot",
      "Regulatory Body",
      "Plug-in Depot",
        "Gassing Point",
      "Other",
    ],
    infoPosition: null,
    infoWindow: {
      template: "",
    },
    infoOpened: false,
    currentMarker: null,
    infoOptions: {
      ariaLabel: "Hello",
      minWidth: 200,
      pixelOffset: {
        width: 0,
        height: 0,
      },
    },
    selectAll: false,
    mapDarkMode: false,
    mapType: 'terrain',
    mapLabels: true,
    noLabels: [
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
    ],
    darkLabels: [],
    noIcons: [],
    noLabelsDark: [
      { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
      },
      {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit.station",
        elementType: "labels.text",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
      },
    ],
    mapDark:[
      { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6b9a76" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
      },
      {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
      },
    ],


  }),

  computed: {
    google: getGoogleMapsAPI,


    displayMarkers() {
      return this.poiList.filter((x) => this.selectedMarkers.includes(x.id));
    },

    filteredSelectedFunctions() {
      return this.selectedFunction.slice(0, this.functionLimit);
    },

    mapConfig() {
      if(!this.mapLabels) {
        if(this.mapDarkMode) {
          return this.noLabelsDark
        } else {
          return this.noLabels
        }
      } else if(this.mapDarkMode) {
        return this.mapDark
      } else {
        return []
      }
    },

    drivingInfo() {
      let drivingTime = 0;
      let drivingDistance = 0;
      let drivingTimeFormatted = null
      let drivingDistanceFormatted = null
      let truckTimeFormatted
      if(this.fullRoute.length > 0) {
        this.fullRoute.forEach((leg) => {
          drivingTime += leg.duration.value;
          drivingDistance += leg.distance.value;
          });
          // Convert drivingTime to '# hours # minutes' format from a value like 3758 which should mean 1 hour 3 minutes
          const hours = Math.ceil(drivingTime / 3600);
          const minutes = Math.ceil((drivingTime % 3600) / 60);
          const truckHours = Math.ceil((drivingTime * 1.5) / 3600);
          const truckMinutes = Math.ceil(((drivingTime * 1.5) % 3600) / 60);
          drivingTimeFormatted = `${hours} h ${minutes} min`;
          truckTimeFormatted = `${truckHours} h ${truckMinutes} min`;
          drivingDistanceFormatted = `${(drivingDistance / 1000).toFixed(2)} km`;
      }
      return {
        drivingTimeFormatted,
        drivingDistanceFormatted,
        truckTimeFormatted
      }

    }

  },

  created() {
    this.getCountries();
    this.getMarkers();
  },


  watch: {
    selectAll(val) {
      if (val) {
        this.selectedMarkers = this.poiList.map(x => x.id)
      } else {
        this.selectedMarkers = [];
      }
    },

    async searchOrganisations(val) {
      if (this.searchingOrganisations) {
        clearTimeout(this.organisationTimeout);
      }
      if (val && val.length > 2) {
        this.organisationTimeout = setTimeout(async () => {
          this.searchingOrganisations = true;
          this.organisations = await this.$API.getOrganisationsPOI({
            params: {
              search: val,
              limit: 15,
            },
          });
          this.searchingOrganisations = false;
        }, 150);
        this.searchingOrganisations = false;
      } else {
        clearTimeout(this.organisationTimeout);
        this.searchingOrganisations = false;
      }
    },

    async searchAliasOrganisations(val) {
      if (this.searchingAliasOrganisations) {
        clearTimeout(this.aliasOrgTimeout);
      }
      if (val && val.length > 2) {
        this.aliasOrgTimeout = setTimeout(async () => {
          this.searchingAliasOrganisations = true;
          this.aliasOrganisations = await this.$API.getOrganisationsPOI({
            params: {
              search: val,
              limit: 15,
            },
          });
          this.searchingAliasOrganisations = false;
        }, 150);
        this.searchingAliasOrganisations = false;
      } else {
        clearTimeout(this.aliasOrgTimeout);
        this.searchingAliasOrganisations = false;
      }
    },

    async searchLocodes(val) {
      if (this.searchingLocodes) {
        clearTimeout(this.locodeTimeout);
      }
      if (val && val.length > 2) {
        this.locodeTimeout = setTimeout(async () => {
          this.searchingLocodes = true;
          this.locodes = await this.$API.searchLocodes({
            params: {
              filter: this.selectedCountry.iso2,
              search: val,
              limit: 15,
            },
          });
          this.searchingLocodes = false;
        }, 150);
        this.searchingLocodes = false;
      } else {
        clearTimeout(this.locodeTimeout);
        this.searchingLocodes = false;
      }
    },

    'aliasToAdd.alias': {
      handler: function (val) {
        if (val && val.length >= 2) {
          this.aliasDisabled = false;
        } else {
          this.aliasDisabled = true;
        }
      }
    },

    'routeWaypoints': {
      handler: function (val) {
        this.getRoute();
      }
    },

    'params.limit' : {
      handler: function (val) {
        this.getMarkers();
      }
    },

    'params.search' : {
      handler: function (val) {
        this.getMarkers();
      }
    },

    'params.filter.functionality' : {
      handler: function (val) {
        this.getMarkers();
      }
    },

    'params.filter.subdivision' : {
      handler: function (val) {
        this.getMarkers();
      }
    },

    async fboSearchAll(val) {
      if (this.searchingFbos) {
        clearTimeout(this.fboSearchTimeout);
      }
      if (val && val.length > 2) {
        this.fboSearchTimeout = setTimeout(async () => {
          this.searchingFbos = true;
          this.moreFboCodes = await this.$API.getFboCodes({
            params: {
              search: val,
              limit: 15,
            },
          });
          this.searchingFbos = false;
          this.fboSearchTimeout = null;
          this.fboList++;
        }, 150);
      } else {
        clearTimeout(this.fboSearchTimeout);
      }
    },

    async acitvityPointSearch(val) {
      if (this.searchingActivityPoints) {
        clearTimeout(this.activitySearchTimeout);
      }
      if (val && val.length > 2) {
        this.activitySearchTimeout = setTimeout(async () => {
          this.searchingActivityPoints = true;
          this.moreActivities = await this.$API.getActivityPoints({
            params: {
              search: val,
              limit: 20,
            },
          });
          this.searchingActivityPoints = false;
          this.activitySearchTimeout = null;
          this.actPointList++;
        }, 150);
      } else {
        clearTimeout(this.activitySearchTimeout);
      }
    },
  },

  
  methods: {

    // POI's //////////////////////////// POI's ///////////////////////////////////////////////// POI's //////////////////////////////////////// POI's /////////////////////////// POI's //////////////
    async deletePoi() {
      this.deleteCard = false;
      let result = await this.$API.deletePoi(this.chosenPoi.id)
      if(result) {
        this.$store.dispatch("getPois");
        this.clearPoi();
        this.$message({
        message: "Poi deleted.",
        type: "success",
      });
      }
    },

    enforceSelectionLimit() {
      if (this.selectedFunction.length > this.functionLimit) {
        this.selectedFunction = this.selectedFunction.slice(0, this.functionLimit);
      }
    },

    checkRequired(val) {
      if(val == 'function') {
        this.enforceSelectionLimit();
      }
      if (
        this.fboCodeUsed == false &&
        this.placeName != null &&
        this.selectedFunction != null &&
        // this.selectedOrganisation != null  &&
        this.chosenLatitude != null &&
        this.chosenLongitude != null
      ) {
        this.saveDisabled = false;
      } else {
        this.saveDisabled = true;
      }
    },

    clearPoi() {
      this.editingPoi= false;
      this.placeName = null;
      this.friendlyName = null
      this.locationCode = null
      this.formattedAddress = null;
      this.postalCode = null;
      this.selectedFunction = null;
      this.chosenLatitude = null;
      this.chosenLongitude = null;
      this.radius = 0;
      this.province = null;
      this.aliases = [{
        alias: null,
        aliasOrganisation: null
      }];
      this.selectedOrganisation = {
        id: null,
        name: null
      }
      this.organisations = {
        total: 0,
        data: []
      }
      this.selectedFbo = {
        id: null,
        fboCode: null,
        fboName: null
      }
      
      this.selectedAP = {
        id: null,
        name: null,
        code: null
      }

      this.selectedLocode = {
        id: null,
        locode: null,
        name: null
      }
      this.locodes = {
        total: 0,
        data: []
      }
    },

    async checkFboCode(item) {
      if (item != null) {
        let validation = await this.$API.checkFboCode(item.fboCode);
        if(validation.status == false) {
          this.fboCodeUsed = false;
        } else if(validation.status == true) {
          this.fboCodeUsed = true;
        }
      }
      else this.fboCodeUsed = false;
    },

    clearFboSelected() {
      this.fboCodeUsed = false;
      this.selectedFbo = {
        id: null,
        fboCode: null,
        fboName: null
      }
    },

    async updatePoi() {
      this.updateLoading = true;
      let data = {
        id: this.chosenPoi.id,
        name: this.placeName,
        friendlyName: this.friendlyName,
        locationCode: this.locationCode,
        address: this.formattedAddress,
        city:  this.selectedLocode ? this.selectedLocode.name : this.vicinity,
        country: this.country,
        subdivision: this.province,
        postalCode: this.postalCode,
        lat: this.chosenLatitude,
        lng: this.chosenLongitude,
        radius: this.radius,
        organisationId: this.selectedOrganisation ? this.selectedOrganisation.id : null,
        organisationFboId: this.selectedFbo.id,
        fboCode: this.selectedFbo.fboCode,
        unLocodeId: this.selectedLocode ? this.selectedLocode.id : null,
      };
      let poi = await this.$API.updatePoi(data);                                                                                //Update the POI
      if (this.selectedAP.name && poi) {
        let activityPoint = {
        id: this.selectedAP.id,
        organisationId: this.selectedOrganisation.id,
        poiId: poi.id,
        };
        await this.$API.updateActivityPoint(activityPoint);
      }
      if(this.chosenPoi.poiFunctions.length != 0 && this.selectedFunction != null) {                                            //If the POI has functions and the user has selected functions
        if(this.chosenPoi.poiFunctions.length == this.selectedFunction.length ) {                                               //If the length of the POI's functions are equal to that of 
          for(let i = 0; i < this.selectedFunction.length ; i++) {                                                              //the length of the selected functions, then replace those functions
           let poiFunction = {
              id: this.chosenPoi.poiFunctions[i].id,
              poiId: this.chosenPoi.id,
              functionality: this.selectedFunction[i],
            };
            let result = await this.$API.updatePoiFunction(poiFunction);
            this.pois.find(poi => poi.id == this.chosenPoi.id).poiFunctions[i] = result;
          }
        }
        else if(this.chosenPoi.poiFunctions.length > this.selectedFunction.length) {                                            
          for(let i = 0; i < this.selectedFunction.length; i++) {                                                               //If the length of the POI's functions are greater than that of
           let poiFunction = {                                                                                                  //the selected functions, then replace them and delete the rest
              id: this.chosenPoi.poiFunctions[i].id,
              poiId: this.chosenPoi.id,
              functionality: this.selectedFunction[i],
            };
            let result = await this.$API.updatePoiFunction(poiFunction);
            this.pois.find(poi => poi.id == this.chosenPoi.id).poiFunctions[i] = result;
          }
          for(let i = this.selectedFunction.length; i < this.chosenPoi.poiFunctions.length; i++) {
            await this.$API.deletePoiFunction(this.chosenPoi.poiFunctions[i].id);
            this.pois.find(poi => poi.id == this.chosenPoi.id).poiFunctions.splice(i, 1);
          }
        }
        else if(this.chosenPoi.poiFunctions.length < this.selectedFunction.length) {                                            //If the length of the POI's functions are less than that of
          for(let i = 0; i < this.chosenPoi.poiFunctions.length; i++) {                                                         //the selected functions, then replace those and create the rest
           let poiFunction = {
              id: this.chosenPoi.poiFunctions[i].id,
              poiId: this.chosenPoi.id,
              functionality: this.selectedFunction[i],
            };
            let result = await this.$API.updatePoiFunction(poiFunction);
            this.pois.find(poi => poi.id == this.chosenPoi.id).poiFunctions[i] = result;
          }
          for(let i = this.chosenPoi.poiFunctions.length; i < this.selectedFunction.length; i++) {
            let result = await this.$API.createPoiFunction({poiId: poi.id, functionality: this.selectedFunction[i]});
            this.pois.find(poi => poi.id == this.chosenPoi.id).poiFunctions.push(result);
          }
        }
      }
        else {                                                                                                                //If the POI has no functions and the user has selected functions
          if(this.selectedFunction != null) {                                                                                 //then create those functions
            this.selectedFunction.forEach(async x =>  {
            let result = await this.$API.createPoiFunction({poiId: poi.id, functionality: x})
            x = result;
            this.pois.find(poi => poi.id == this.chosenPoi.id).poiFunctions.push(result);
        })
        }
      }
      if(this.aliases[0].alias != null && this.aliases[0].alias != "") {                                                       //If the user has entered aliases, then create them
        
        this.aliases.forEach(async x => {
          if(x.new) {
            let result = await this.$API.createPoiAlias({poiId: poi.id, alias: x.alias, aliasType: x.aliasType, organisationId: x.organisationId});
            x.id = result.id;
          } 
          else {
            let result = await this.$API.updatePoiAlias({id: x.id, poiId: poi.id, alias: x.alias, aliasType: x.aliasType, organisationId: x.organisationId});
            x = result;
          }
          });
        this.deletedAliases.forEach(async x => {
          if(Object.hasOwn(x, "id")){
              await this.$API.deletePoiAlias(x.id);
            } else {
              return;
            }
        });
        this.deletedAliases = [];
        this.pois.find(poi => poi.id == this.chosenPoi.id).poiAliases = this.aliases;
      }
      let find = this.pois.findIndex(poi => poi.id == this.chosenPoi.id)
      console.log(find)
      this.pois[find].friendlyName = data.friendlyName
      console.log(this.pois[find])

      this.updateLoading = false;
      this.clearPoi();
      this.$message({
        message: "Poi updated successfully",
        type: "success",
      });
    },

    async createPoi() {
      this.saveLoading = true;
      let data = {
        name: this.placeName,
        friendlyName: this.friendlyName,
        locationCode: this.locationCode,
        address: this.formattedAddress,
        city: this.selectedLocode ? this.selectedLocode.name : this.vicinity,
        country: this.country,
        subdivision: this.province,
        postalCode: this.postalCode,
        lat: this.chosenLatitude,
        lng: this.chosenLongitude,
        radius: this.radius,
        organisationId: this.selectedOrganisation ? this.selectedOrganisation.id : null,
        organisationFboId: this.selectedFbo.id,
        fboCode: this.selectedFbo.fboCode,
        unLocodeId: this.selectedLocode ? this.selectedLocode.id : null,
      };

      let poi = await this.$API.createPoi(data);

      if(this.selectedFunction != null && poi) {
        this.selectedFunction.forEach(async x =>  {
          await this.$API.createPoiFunction({poiId: poi.id, functionality: x})
        })
      }

      if(this.aliases[0].alias != null) {
        this.aliases.forEach(async x => {
          let result = await this.$API.createPoiAlias({poiId: poi.id, alias: x.alias, aliasType: x.aliasType, organisationId: x.organisationId});
        })
      }

      if (this.selectedAP.name && poi) {
        let activityPoint = {
        id: this.selectedAP.id,
        organisationId: this.selectedOrganisation ? this.selectedOrganisation.id : null,
        poiId: poi.id,
        };
        await this.$API.updateActivityPoint(activityPoint);
      }
      let newPoi = {
        ...poi,
        organisation: this.selectedOrganisation,
        organisationFbo: this.selectedFbo,
        activityPoint: this.selectedAP,
        unLocode: this.selectedLocode,
        poiFunctions: this.selectedFunction ? this.selectedFunction.map(x => ({poiId: poi.id, functionality: x})) : [],
        poiAliases: this.aliases,
      }
      this.poiList.push(newPoi);
      this.pois.push(newPoi);
      this.clearPoi();
      this.saveLoading = false;
    },

    addAlias() {
      console.log(this.aliasToAdd)
      if(this.aliases[0].alias != null) {
      if(this.aliasToAdd.alias != null && this.aliasToAdd.alias != "") {
        if(this.aliasToAdd.aliasOrganisation != null) {
          this.aliases.push({ alias: this.aliasToAdd.alias, aliasType: this.aliasToAdd.aliasOrganisation.name , organisationId: this.aliasToAdd.aliasOrganisation.id, new: true});
          this.aliasKey++;
          this.aliasToAdd = {
            alias: null,
            aliasOrganisation: null,
          };
        } else {
          this.aliases.push({ alias: this.aliasToAdd.alias, aliasType: null, organisationId: null, new: true});
          this.aliasKey++;
          this.aliasToAdd = {
            alias: null,
            aliasOrganisation: null,
          }
        }

      } else this.$message({
        message: "Alias cannot be empty",
        type: "error",
      });
    } else {
      if(this.aliasToAdd.alias != null && this.aliasToAdd.alias != "") {
        if(this.aliasToAdd.aliasOrganisation != null) {
          this.aliases[0] = { alias: this.aliasToAdd.alias, aliasType: this.aliasToAdd.aliasOrganisation.name , organisationId: this.aliasToAdd.aliasOrganisation.id, new: true}
          this.aliasKey++;
          this.aliasToAdd = {
            alias: null,
            aliasOrganisation: null,
          }
        } else {
          this.aliases[0] = { alias: this.aliasToAdd.alias, aliasType: null, organisationId: null, new: true}
          this.aliasKey++;
          this.aliasToAdd = {
            alias: null,
            aliasOrganisation: null,
          }
        }
      } else this.$message({
        message: "Alias cannot be empty",
        type: "error",
      });
    }
    },

    async removeAlias(alias) {
      this.deletedAliases.push(alias);
      console.log(this.deletedAliases)
      
      if(this.aliases.length > 1) {
       let index = this.aliases.findIndex(x => x.alias === alias.alias);
          if (index !== -1) {
            this.aliases.splice(index, 1);
          }
      } else {
        this.aliases = [{alias: null, aliasType: null, organisationId: null}]
      }
    },

    selectFbo(item) {
      this.selectedFbo = item;
      this.checkFboCode(item);
      this.fboCard = false;
    },

    selectActivityPoint(item) {
      this.selectedAP = item;
      this.activityPointCard = false;
    },

    clearPlace() {
      this.place = null;
      (this.customMarker = true), (this.formattedAddress = "");
      this.placeName = "";
      this.friendlyName = ""
      this.locationCode = ""
      this.country = " ";
      this.address = " ";
      this.coordinates = " ";
      this.chosenLatitude = " ";
      this.chosenLongitude = " ";
      this.postalCode = " ";
    },

    addFbo() {
      if (this.fboCard) {
        this.fboCard = false;
      } else this.fboCard = true;
    },

    openAlias() {
      if (this.aliasCard) {
        this.aliasCard = false;
      } else this.aliasCard = true;
    },

    addActivityPoint() {
      if (this.activityPointCard) {
        this.activityPointCard = false;
      } else this.activityPointCard = true;
    },

    filterPois() {
      if (this.filterPoiCard === true) {
        this.filterPoiCard = false;
      } else {
        this.filterPoiCard = true;
      }
    },

    async getFboCodes(val) {
      this.fboCodes = await this.$API.getOrgFboCodes(val.id);
    },



    // ROUTES //////////////////////////// ROUTES ///////////////////////////////////////////////// ROUTES //////////////////////////////////////// ROUTES /////////////////////////// ROUTES //////////////
    async getRoute() {
      if (this.routeWaypoints.length <= 1) {
        this.route = null;
        this.legs = [];
        return;
      }
      if(this.routeWaypoints.length > 1 && this.routeWaypoints[0].name != null && this.routeWaypoints[this.routeWaypoints.length -1].name != null ) {
          let start = this.routeWaypoints[0];
          let end = this.routeWaypoints[this.routeWaypoints.length - 1];
          let waypoints = null;


          if(this.routeWaypoints.length > 2) {
          let waypnts = this.routeWaypoints.slice(1, this.routeWaypoints.length - 1);
          waypoints = waypnts.map((w) => {
            return {
              location: new this.google.maps.LatLng(parseFloat(w.lat), parseFloat(w.lng)),
              stopover: true,
            };
          });

        }
          const directionsService = new this.google.maps.DirectionsService();
          const origin = new this.google.maps.LatLng(parseFloat(start.lat), parseFloat(start.lng));
          const destination = new this.google.maps.LatLng(parseFloat(end.lat),parseFloat(end.lng));
          this.legs = [];
          this.fullRoute = [];
          const request = {
              origin,
              destination,
              waypoints: waypoints ? waypoints : [],
              travelMode: "DRIVING",
          };

          directionsService.route(request, (result, status) => {
              if (status === "OK") {
                  console.log('Got something back: ', result)
                  let colorIndex = 0;
                  this.route = result.routes[0].overview_path;
                  this.fullRoute = result.routes[0].legs;
                  result.routes[0].legs.forEach((leg) => {
                      leg.steps.forEach((step) => {
                          this.legs.push(step);
                          step.color = this.colors[colorIndex % this.colors.length];
                      });
                      colorIndex++;
                  });
              } else {
                  console.error("Directions request failed due to " + status);
              }
          });
        } 
        },

    removeWaypoint(waypoint) {
      const index = this.routeWaypoints.indexOf(waypoint);
      if (index !== -1) {
      this.routeWaypoints = this.routeWaypoints.filter((w, i) => i !== index);
      this.getRoute();
    }
  },

    addWaypoint() {
      this.routeWaypoints.push({name: null, lat: null, lng: null})
    },

    updateWaypoint(waypoint, val, index) {
      console.log('🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀',waypoint, val)
      if(index != -1) {
        this.routeWaypoints[index].name = val.name;
        this.routeWaypoints[index].lat = parseFloat(val.lat);
        this.routeWaypoints[index].lng = parseFloat(val.lng)
        this.getRoute();
      }
      else {
        this.getRoute();
      }
    },

    async savePath(data) {
      let obj = {
        name: this.routeName,
        startLat: this.routeWaypoints[0].lat,
        startLng: this.routeWaypoints[0].lng,
        startPoiId: this.routeWaypoints[0].id? this.routeWaypoints[0].id : null,
        endLat: this.routeWaypoints[this.routeWaypoints.length - 1].lat,
        endLng: this.routeWaypoints[this.routeWaypoints.length - 1].lng,
        endPoiId: this.routeWaypoints[this.routeWaypoints.length - 1].id? this.routeWaypoints[this.routeWaypoints.length - 1].id : null,
        distance: this.drivingInfo.drivingDistanceFormatted ,
        duration: this.drivingInfo.drivingTimeFormatted,
        path: this.legs,
      }
    },


    // MAP //////////////////////////// MAP ///////////////////////////////////////////////// MAP //////////////////////////////////////// MAP /////////////////////////// MAP //////////////

    getZoom(zoom) {
      this.zoomLevel = zoom;
    },

    getBounds(bounds) {
      clearTimeout(this.boundsTimer);
      this.boundsTimer = setTimeout(() => {
        let values = Object.values(bounds);
        this.currentBounds.north = values[0].hi;
        this.currentBounds.south = values[0].lo;
        this.currentBounds.east = values[1].hi;
        this.currentBounds.west = values[1].lo;
      }, 2000);
    },

    checkIfInBounds() {
      let latLng = {
        lat: this.chosenLatitude,
        lng: this.chosenLongitude,
      };
      let bounds = {
        north: this.currentBounds.north,
        south: this.currentBounds.south,
        east: this.currentBounds.east,
        west: this.currentBounds.west,
      };
      if (
        latLng.lat > bounds.south &&
        latLng.lat < bounds.north &&
        latLng.lng > bounds.west &&
        latLng.lng < bounds.east
      ) {
        return true;
      } else {
        return false;
      }
    },

    updateCoordinates(data) {
      this.chosenLatitude = data.latLng.lat();
        this.chosenLatitude = this.chosenLatitude.toFixed(7);
        this.chosenLongitude = data.latLng.lng();
        this.chosenLongitude = this.chosenLongitude.toFixed(7);
    },

    // MARKERS //////////////////////////// MARKERS ///////////////////////////////////////////////// MARKERS //////////////////////////////////////// MARKERS /////////////////////////// MARKERS //////////////
    setPlace(place) {
      this.place = place;
      this.formattedAddress = place.formatted_address;
      this.placeName = place.name;
      this.friendlyName = null
      this.country = place.formatted_address.split(",").pop();
      this.center = place.geometry.location;
      this.address = this.place.vicinity;
      this.coordinates = this.place.geometry.location;
      this.customMarker = false;
      this.chosenLatitude = this.coordinates
        .toString()
        .replace(/[()]/g, "")
        .split(",")[0];
      this.chosenLongitude = this.coordinates
        .toString()
        .replace(/[()]/g, "")
        .split(",")[1];
      for (let i = 0; i < this.place.address_components.length; i++) {
        if (this.place.address_components[i].types[0] == "postal_code") {
          this.postalCode = this.place.address_components[i].long_name;
        }
      }
      for (let i = 0; i < this.place.address_components.length; i++) {
        if (
          this.place.address_components[i].types[0] ==
          "administrative_area_level_1"
        ) {
          this.province = this.place.address_components[i].long_name;
        }
      }
    },
    
    toggleInfoWindow(marker) {
      console.log(marker);
      this.infoPosition = {
        lat: marker.lat,
        lng: marker.lng,
      };
      this.infoWindow.template = `<p style="color:black"><b><u>${marker.name}</u></b><br>${marker.function1}<br>${marker.address} <br><b>FBO-Code:</b>  ${marker.fboCode}</p>`;

      if (this.currentMarker == marker.id) {
        this.infoOpened = !this.infoOpened;
      } else {
        this.infoOpened = true;
        this.currentMarker = marker.id;
      }
    },
    
    viewMarker(poi) {
      console.log(poi)
      this.chosenPoi = poi;
      this.editingPoi= true;
      this.center = {
        lat: poi.lat,
        lng: poi.lng,
      };
      this.placeName = poi.name
      this.friendlyName = poi.friendlyName
      this.locationCode = poi.locationCode
      this.formattedAddress = poi.address
      this.postalCode = poi.postalCode
      this.chosenLatitude = poi.lat
      this.chosenLongitude = poi.lng
      this.radius = poi.radius
      this.province = poi.subdivision
      if(poi.organisation != null) {
      this.selectedOrganisation = {
        id: poi.organisationId,
        name: poi.organisation.name
      }

      this.organisations = {
        total: 1,
        data: [this.selectedOrganisation]
      }
      } else {
        this.selectedOrganisation = {
          id: null,
          name: null
        }
        this.organisations = {
          total: 0,
          data: []
        }
      }
      if(poi.organisationFbo != null) {
        this.selectedFbo = {
        id: poi.organisationFboId,
        fboCode: poi.fboCode,
        fboName: poi.organisationFbo.fboName
      }
      } else {
        this.selectedFbo = {
        id: null,
        fboCode: null,
        fboName: null
      }
      }
      if(poi.activityPoint != null) {
        this.selectedAP = {
        id: poi.activityPoint.id,
        name: poi.activityPoint.name,
        code: poi.activityPoint.code
      }
      } else {
        this.selectedAP = {
        id: null,
        name: null,
        code: null
      }
      }
      if(poi.unLocode != null){
        this.selectedLocode = {
        id: poi.unLocode.id,
        locode: poi.unLocode.locode,
        name: poi.unLocode.name
      }
      this.locodes = {
        total: 1,
        data: [this.selectedLocode]
      }
      } else {
        this.selectedLocode = {
        id: null,
        locode: null,
        name: null
      }
      this.locodes = {
        total: 0,
        data: []
      }
      }
      if(poi.poiAliases != null && poi.poiAliases.length != 0) {
        this.existingAliases = poi.poiAliases
        console.log(this.existingAliases)
        this.aliases = poi.poiAliases
        console.log(this.aliases)
      } else {
        this.aliases = [{
          alias: null,
          aliasOrganisation: null
        }]
      }
      if (poi.poiFunctions != null) {
        this.selectedFunction = poi.poiFunctions.slice(0, 5).map(func => func.functionality);
      }
    },

    clearFilters() {
      this.functionFilter = null;
      this.subdivisionFilter = null;
      this.filtersActive = false;
      if (this.searchPois == null) {
        this.poiList = this.pois;
      }
    },

    async viewAllMarkers() {
      if (this.viewMarkers) {
        this.viewMarkers = false;
        this.filterPoiCard = false;
      } else this.viewMarkers = true;

      if (this.pois.length == 0) {
        this.getMarkers();
      }
    },

    async getMarkers() {
        this.loadingPoiList = true;
        this.pois = await this.$API.getPois({
          params: this.params
        });

        this.loadingPoiList = false;
        this.poiList = this.pois;
    },

    addMarker(data) {
      if (this.viewRoutes) {
        console.log('It got to the first section')
        const newIndex = this.routeWaypoints.findIndex(
          (point) => point.lat === null && point.lng === null
        );
      console.log(newIndex, this.routeWaypoints,  'this is the index it got')
        if (newIndex !== -1) {
          this.routeWaypoints[newIndex].lat = data.latLng.lat()
          this.routeWaypoints[newIndex].lng = data.latLng.lng()
          this.routeWaypoints[newIndex].name = "Custom Marker" + " " + (newIndex + 1);
          this.routesLocationKey++;
          console.log('it assigned it so if it did that then it needs an update key', this.routeWaypoints)
          this.getRoute();
          this.routesLocationKey++;
        }
      } else if (this.customMarker) {
        this.center = {
          lat: data.latLng.lat(),
          lng: data.latLng.lng(),
        };
        console.log(data);
        this.chosenLatitude = data.latLng.lat().toFixed(7);
        this.chosenLongitude = data.latLng.lng().toFixed(7);
      }
},

    // COUNTRIES //////////////////////////// COUNTRIES ///////////////////////////////////////////////// COUNTRIES //////////////////////////////////////// COUNTRIES /////////////////////////// COUNTRIES //////////////

    expandedInfo() {
      if (this.appendIcon == "expand_more") {
        this.appendIcon = "expand_less";
        this.expand = true;
      } else {
        this.appendIcon = "expand_more";
        this.expand = false;
      }
    },

    async getCountries() {
      this.countries = await this.$API.searchCountriesBasic({});
    },

    countryChange() {
      this.$refs.countrySelect.blur();
      this.revealCountryAuto = false;
      this.countrySelected = {
        country: `${this.selectedCountry.iso2.toLowerCase()}`,
      };
      this.autocompleteKey++;
      if (this.selectedCountry && !this.revealCountryAuto) {
        this.countryCollision = false;
      }
    },

    countryClear() {
      this.countryCollision = true;
    },

    revealCountry() {
      if (this.revealCountryAuto) {
        this.revealCountryAuto = false;
        this.countryCollision = false;
      } else if (this.countryCollision && !this.revealCountryAuto) {
        this.countryCollision = false;
      } else {
        this.revealCountryAuto = true;
        this.filterPoiCard = false;
        this.countryCollision = true;
      }
    },

    radiusCard() {
      if (this.radiusTool) {
        this.radiusTool = false;
      } else this.radiusTool = true;
    },
  },
};


</script>

<style scoped>
/* MapView */
.mapLocal {
  height: 90.5vh;
  width: 100%;
}

.markerWaypoints {
  color: #ffffff !important;
}

.mapLocal ::v-deep .gm-style-cc {
  right: 30vw !important;
}

.mapLocal ::v-deep .vue-map {
    border-radius: 0px 0px 50px 50px;
}

.mapLocal ::v-deep .gm-style div[style*="z-index: 1000000"] {
  left: 20px !important;
  bottom: 10px !important;
  scale: 1.5 !important;
}

.transition {
  transition: all 3s;
}

.infoFields {
  padding-left: 2vw;
  padding-top: 1vh;
}

.infoTitleText {
  font-size: 2vh;
}

.floatingTabsRight {
  display: flex;
  align-items: center;
  position: absolute;
  height: 5vh;
  transition: .4s;
  padding: 0 0 0 1vw;
  border-radius: 16px 0 0 16px ;
  z-index: 1;
  width: 9.5vw;
  right: -6vw;
}

.floatingTabsLeft {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  height: 5vh;
  transition: .4s;
  padding: 0 1vw 0 0;
  border-radius: 0 16px 16px 0;
  z-index: 1;
  width: 9.5vw;
  left: -6vw;
}
.on-hover-right{
  right: 0vw;
}
.on-hover-left{
  left: 0vw;
}

.firstTab {
  top: 6vh;
}
.secondTab {
  top: 12vh;
}
.thirdTab {
  top: 18vh;
}
.fourthTab {
  top: 24vh;

}


.closeNav {
  position: absolute;
  top: 1vh;
  right: 0.5vw;
}

.infoguide {
  text-decoration-color: black;
}

.routeLocationCard {
  height: 8vh;
  width: 100%;
  border-radius: 16px;
  background-color: #aaaaaa !important;
}
.infoHide {
  position: absolute;
  left: 0.5vw;
  top: 1vh;
  z-index: 1;
}

.poiSearch {
  width: 15vw;
}

.dataSearch {
  width: 15vw;
  margin-top: 1vh;
  margin-bottom: -1vh;
}

.aliasAddBar {
  width: 18vw;
  margin-top: 1vh;
  margin-bottom: -1vh;
}

.mapTypeBtn ::v-deep .v-btn__content {
  justify-content:start;
}

.activityPoint {
  width: 15vw;
}
.latLng {
  width: 7vw;
}

.infoSave {
  position: absolute;
  bottom: 1.5vh;
  right: 1vw;
  left: auto;
}

.listToolbar {
  height: 3.5vh !important;
  contain: content;
  scroll-snap-align: start;
}

.toolbar {
  position: absolute;
  border-radius: 16px;
  z-index: 15 !important;
  height: 6vh;
  width: 30vw;
  top: 0.5vh;
  left: 33%;
  text-align: center;
  padding-top: 0.2vh;
}


.radiusTool {
  position: absolute;
  border-radius: 16px;
  height: 5.5vh;
  width: 15vw;
  top: 7.7vh;
  left: 41%;
  padding: 0 0.8vw 0 0.8vw;
  z-index: 1;
}

.countryAvatar {
  width: 1.5vw;
  margin: 2vh 0 2vh 1.5vw;
}

.countrySelectCard {
  position: absolute;
  border-radius: 16px;
  height: 5.5vh;
  width: 16vw;
  top: 0.75vh;
  left: 18.5%;
  z-index: 1;
}

.countryAuto {
  width: 12.5vw;
  margin: 0.7vh 0 1vh 0.5vw;
}

.countryList {
  border-bottom-left-radius: 16px;
}

.informationCard {
  position: absolute;
  overflow-y: scroll;
  overflow-x:hidden;
  border-radius: 16px;
  height: 78vh;
  width: 17vw;
  top: 6vh;
  right: 0.5vw;
  z-index: 2000 !important;
  contain:content;
}

.infoCardTiles {
  background-color: #2e2d2d !important;
  width: 100%;
  border-radius: 16px;
  
}

.navCard {
  position: absolute;
  border-radius: 16px;
  height: 72vh;
  width: 17vw;
  top: 6vh;
  left: 0.5vw;
  z-index: 2000 !important;
}

.filterPoi {
  position: absolute;
  border-radius: 16px;
  height: 20vh;
  width: 13vw;
  top: 11vh;
  left: 17.8vw;
  z-index: 1;
}

.filterOff {
  position: absolute;
  top: 0.8vh;
  right: 0.8vw;
}

.locationInput {
  margin: 2vh 0 0 0.8vw;
  border-radius: 16px;
  line-height: 4vh;
  width: 20vw;
  z-index: 2000 !important;
}

.loadingPoi {
  position: absolute;
  top: 20vh;
  left: 6.7vw;
}

.locationData {
  border-radius: 10px;
  font-size: small;
}

.locationAddress {
  border-radius: 10px !important;
  font-size: small;
}

.routeWaypoint {
  width: 100%;
  border-radius: 16px;
  height: 5vh;
  /* background-color: #aaaaaa !important; */
}

.expandedInfo {
  position: absolute;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 3vh 0.5vw 0 0.5vw;
  height: 23vh;
  width: 21vw;
  left: 34%;
  z-index: 1;
  top: 4vh;
}

/* Animations */
.slide-btn-leave-active {
  transition: 0.1s;
}
.slide-btn-enter-active {
  transition: 1s;
}
.slide-btn-enter {
  transform: translateX(100%);
}
.slide-btn-leave-to {
  transform: translateX(100%);
}
.slide-country-leave-active {
  transition: 0.3s;
}
.slide-country-enter-active {
  transition: 0.5s;
}
.slide-country-enter {
  transform: translateX(100%);
}
.slide-country-leave-to {
  transform: translateX(100%);
}

.slide-info-leave-active {
  transition: 0.5s;
}
.slide-info-enter-active {
  transition: 0.8s;
}
.slide-info-enter {
  transform: translateX(100%);
}
.slide-info-leave-to {
  transform: translateX(100%);
}
.slide-top-leave-active {
  transition: 0.3s;
}
.slide-top-enter-active {
  transition: 0.5s;
}
.slide-top-enter {
  transform: translateY(-120%);
}
.slide-top-leave-to {
  transform: translateY(-120%);
}
.slide-marker-leave-active {
  transition: 0.1s;
}
.slide-marker-enter-active {
  transition: 1s;
}
.slide-marker-enter {
  transform: translateX(-100%);
}
.slide-marker-leave-to {
  transform: translateX(-100%);
}
.slide-nav-leave-active {
  transition: 0.5s;
}
.slide-nav-enter-active {
  transition: 0.8s;
}
.slide-nav-enter {
  transform: translateX(-100%);
}
.slide-nav-leave-to {
  transform: translateX(-100%);
}

.filter-leave-active {
  transition: 0.3s;
}
.filter-enter-active {
  transition: 0.3s;
  transform: translateX(-100%);
  
}

.filter-enter-to {
  transform: translateX(0%);
  
}
.filter-leave-to {
  transform: translateX(-110%);
  opacity: 0;
}
</style>
